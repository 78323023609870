import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const Img = makeShortcode("Img");
const TextCustom = makeShortcode("TextCustom");
const CheckCircle = makeShortcode("CheckCircle");
const EmoticonExcited = makeShortcode("EmoticonExcited");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Grid container mdxType="Grid">
  <Grid item xs={12} sm={8} md={9} mdxType="Grid">
    <h1>Effective problem-solving</h1>
    <h3>4. Decide on a solution.</h3>
    <p>Choose a solution or a few solutions that your teenager thinks will work best.</p>
    <p>You now need to plan exactly how it will work.</p>
    <ul>
      <li>Who will do what?</li>
      <li>When will they do it?</li>
      <li>What’s needed to put the solution into action?</li>
    </ul>
  </Grid>
  <Grid xs={12} sm={4} md={3} item mdxType="Grid">
    <Img src="/images/m8/Mod7-p5.svg" alt="encourage" mdxType="Img" />
  </Grid>
    </Grid>
    <p><strong parentName="p">{`Next, write out a timeline of the action plan`}</strong>{` to help you and your teenager see when to review the solution.`}</p>
    <p>{`For example, the plan of action towards improving Maths grades includes doing Maths exercises every night as homework. It will help if your plan outlines `}<strong parentName="p">{`what time`}</strong>{` this will happen, `}<strong parentName="p">{`where`}</strong>{` it will take place and `}<strong parentName="p">{`which`}</strong>{` exercises will be done. The plan could be reviewed after a week to see how the study routine is going, and then in a month’s time to see whether grades have improved.`}</p>
    <table>
  <thead>
    <tr>
      <th>Action steps for improving Maths grades</th>
      <th>When</th>
      <th>Done</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Get extra Maths exercises from Mr. Smith and use these each evening.</td>
      <td>Week 1</td>
      <td>
        <TextCustom customColor="green" mdxType="TextCustom">
          <CheckCircle mdxType="CheckCircle" />
        </TextCustom>
      </td>
    </tr>
    <tr>
      <td>Find online study guides and use these each evening.</td>
      <td>Week 2</td>
      <td>
        <TextCustom customColor="green" mdxType="TextCustom">
          <CheckCircle mdxType="CheckCircle" />
        </TextCustom>
      </td>
    </tr>
    <tr>
      <td>Go to the library and borrow last year's exam, use as a practice exam for next week.</td>
      <td>Week 3</td>
      <td>
        <TextCustom customColor="green" mdxType="TextCustom">
          <CheckCircle mdxType="CheckCircle" />
        </TextCustom>
      </td>
    </tr>
    <tr>
      <td>
        <b>Goal: Improve on my first term exam mark by 15%.</b>
      </td>
      <td>
        <b>Week 4</b>
      </td>
      <td>
        <TextCustom customColor="green" mdxType="TextCustom">
          <EmoticonExcited mdxType="EmoticonExcited" />
          <EmoticonExcited mdxType="EmoticonExcited" />
          <EmoticonExcited mdxType="EmoticonExcited" />
        </TextCustom>
      </td>
    </tr>
  </tbody>
    </table>
    <p>{`Your teenager might need some coaching to feel confident with their solution. For example, if they are going to try to resolve a fight with a friend, they might find it helpful to practise with you what they are going to say.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      